export const ui = {
  common: {
    auth: {
      notifications: {
        accountAlreadyExists: {
          description: 'Already an account connected to the this email',
          message: 'Account already exists',
          signup: 'Log in',
        },
        accountDisabled: {
          description: 'Please contact us:',
          message: 'Your account has been disabled',
        },
        accountNotFound: {
          createAccount: "Don't have an account?",
          description: 'We could not find an account connected to this email',
          message: 'Account not found',
          signup: 'Sign up',
        },
        invalidCredentials: {
          description: 'Please check your credentials and try again',
          message: 'Your credentials is incorrect',
          passwordReset: 'Forgot your password?',
          resetPassword: 'Reset password',
        },
        socialEmailError: {
          description:
            'This account is not registered with email, please sign in using other methods',
          message: 'Account creation error',
        },
        somethingWentWrong: {
          description: 'Something went wrong, please try again later',
          message: 'Something went wrong',
        },
      },
    },
    networkListener: {
      accessError: {
        description: 'Our servers are currently unreachable',
        title: 'Access failure',
      },
      intervalServerError: {
        title: 'An unexpected error occurred, please try later.',
      },
      networkError: {
        description: 'Please check your internet connection',
        title: 'Connection problem',
      },
      requestError: { title: 'Your request could not be completed' },
    },
    socialButtons: {
      withApple: 'Continue with Apple',
      withGoogle: 'Continue with Google',
    },
  },
  components: {
    button: {
      action: {
        cancel: { cancel: 'Cancel', close: 'Close', no: 'No' },
        confirm: {
          add: 'Add',
          confirm: 'Confirm',
          create: 'Create',
          ok: 'Ok',
          save: 'Save',
          send: 'Send',
          update: 'Update',
          yes: 'Yes',
        },
      },
    },
    calendar: {
      datepicker: { weeks: { week: 'W' } },
      table: { allday: 'All day' },
    },
    captcha: {
      and: ' and ',
      googleTerms: 'Google terms',
      post: ' apply.',
      pre: 'This site is protected by reCAPTCHA and the ',
      privacyPolicy: 'privacy policy',
    },
    chart: { noData: 'No data' },
    colorPicker: {
      current: 'Current colors',
      preset: 'Colors',
      select: 'Select',
      transparent: 'Transparent',
    },
    form: { action: { cancel: { cancel: 'Cancel', no: 'No' } } },
    imageSlider: { notFound: 'Images not found' },
    indicator: {
      upload: { failed: 'Failed', loaded: 'Loaded', uploading: 'Uploading' },
    },
    inputs: {
      combobox: {
        clear: 'Clear',
        clearAll: 'Clear all',
        loading: 'Loading',
        notFound: 'Not found',
        selectAll: 'Select all',
      },
      file: {
        maxFiles: 'Maximum number of files you can select {{max}}',
        maxSize: 'You can not select file larger than {{size}} MB',
        multiple: 'Select or drag files',
        single: 'Select or drag file',
      },
      image: {
        multiple: 'Select or drag images',
        single: 'Select or drag image',
      },
      listbox: {
        clear: 'Clear',
        clearAll: 'Clear all',
        notFound: 'Not found',
        selectAll: 'Select all',
      },
      richText: {
        clearMarks: 'Clear marks',
        clearNodes: 'Clear nodes',
        heading: 'Heading',
        headings: {
          h1: 'Heading 1',
          h2: 'Heading 2',
          h3: 'Heading 3',
          h4: 'Heading 4',
          h5: 'Heading 5',
          h6: 'Heading 6',
        },
        horizontalRule: 'Horizontal rule',
        image: { title: 'Add image' },
        link: { remove: 'Remove', title: 'Link' },
        node: {
          image: {
            alt: 'Alt text',
            altMissing: 'Alt text missing',
            editAlt: 'Edit',
            height: 'Height',
            save: 'Save',
            saveSize: 'Save',
            weight: 'Width',
          },
        },
      },
      switch: {
        'active-passive': { false: 'Passive', true: 'Active' },
        'enable-disable': { false: 'Disable', true: 'Enable' },
        'open-closed': { false: 'Closed', true: 'Open' },
        'true-false': { false: 'False', true: 'True' },
        'yes-no': { false: 'No', true: 'Yes' },
      },
    },
    notFound: {
      description:
        'This page you are looking for might have been removed had its name changed or is temporarily unavailable.',
      home: 'Return home',
      title: '404 - Page not found',
    },
    table: {
      body: { notFound: { text: 'No data' } },
      caption: { filter: { text: 'Search...' }, refresh: { text: 'Refresh' } },
      pagination: {
        button: {
          end: 'End page',
          next: 'Next',
          previous: 'Previous',
          start: 'First page',
        },
        info: { goto: 'Go to page:', page: 'Page' },
        shown: { title: 'Show' },
      },
    },
    wentWrong: {
      description:
        "Sorry we're having some technical issue. Please try to refresh or return home.",
      home: 'Return home',
      title: 'Something went wrong',
      try: 'Try again',
    },
  },
};
