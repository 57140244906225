export const admin = {
  articles: {
    add: { title: 'Makale ekle' },
    delete: {
      confirm: 'Makaleyi kalıcı olarak silmek istediğinden emin misin?',
      title: 'Makaleyi sil',
    },
    edit: { title: 'Makaleyi düzenle' },
    form: {
      add: {
        slug: 'Slug (Url yolu)',
        slugError: '{{label}} yalnızca "-" içerebilir',
        type: 'Tip',
      },
      edit: {
        content: 'İçerik',
        cover: 'Kapak',
        coverAlt: 'Kapak alt metin',
        description: 'Açıklama',
        slug: 'Slug (Url yolu)',
        status: 'Durum',
        title: 'Başlık',
      },
    },
    header: {
      actions: 'İşlemler',
      slug: 'Slug (Url yolu)',
      status: 'Durum',
      title: 'Başlık',
      type: 'Tip',
    },
    preview: { title: 'Önizle' },
    title: 'Makaleler',
    types: {
      feature: 'Özellik',
      policy: 'Politika',
      tutorial: 'Eğitici',
      widget: 'Araç',
    },
  },
  common: {
    router: {
      article: 'Makale',
      cover: 'Kapak',
      email: 'E-posta',
      home: 'Anasayfa',
      language: {
        fontPack: { title: 'Font paketi' },
        manageLocale: { title: 'Dil düzenle' },
        title: 'Dil',
      },
      login: 'Giriş',
      notFound: '404 - Bulunamadı',
      report: 'Rapor',
      sticker: 'Etiket',
      user: 'Kullanıcı',
      widget: 'Araç',
    },
    sidebar: {
      footer: {
        language: { title: 'Dil' },
        logout: { title: 'Çıkış' },
        theme: {
          options: { dark: 'Koyu', light: 'Açık', system: 'Sistem' },
          title: 'Tema',
        },
        title: { text: 'Ayarlar' },
      },
    },
  },
  email: {
    emails: {
      add: { title: 'E-posta ekle' },
      delete: {
        confirm: 'E-postayı kalıcı olarak silmek istediğinden emin misin?',
        title: 'E-postayı sil',
      },
      form: {
        html: 'E-posta şablon önizleme',
        notSelected: 'Şablon seçilmedi',
        template: 'Şablon',
        title: {
          add: 'E-posta şablonu ekle',
          update: 'E-posta şablonu güncelle',
        },
      },
      header: {
        actions: 'İşlemler',
        createdAt: 'Oluşturulma zamanı',
        template: 'Şablon',
      },
      template: {
        changeEmailRequest: {
          message:
            'Hesabınızla ilişkili e-posta adresinin güncellenmesi yönünde bir talep aldık. Bu isteği siz yaptıysanız değişikliği onaylamak için lütfen aşağıdaki bağlantıya tıklayın:',
          preview:
            'Aşağıdaki bağlantıya tıklayarak e-posta adresi değişikliği isteğinizi onaylayın.',
          title: 'E-posta adresini değiştir',
          verificationLink: 'Doğrulama bağlantısı',
        },
        changeEmailSuccess: {
          description:
            'Bu değişikliği siz yaptıysanız yapmanız gereken başka bir şey yoktur. Bu değişikliği siz yapmadıysanız veya herhangi bir endişeniz varsa lütfen hemen destek ekibimizle iletişime geçin.',
          message:
            'Nowadays hesabınız için e-posta adresinizin başarıyla güncellendiğini size bildirmek istedik.',
          preview: 'Onay: E-posta adresiniz başarıyla güncellendi.',
          title: 'E-posta adresiniz güncellendi',
        },
        createPasswordRequest: {
          createPasswordLink: 'Parolanızı oluşturun',
          description:
            'Parolanızı oluşturmak için aşağıdaki bağlantıya tıklayın:',
          message:
            'Hesabınızın güvenliğini sağlamak için lütfen bir parola oluşturun.',
          preview:
            'Hesabınızı güvence altına alın: Nowadays hesabı için bir parola oluşturun.',
          title: 'Parola oluşturun',
        },
        passwordResetRequest: {
          message:
            'Nowadays hesabınız için parolanızın sıfırlanması yönünde bir talep aldık. Parola sıfırlama işlemine devam etmek için lütfen aşağıdaki bağlantıya tıklayın:',
          preview: 'İşlem gerekli: Parolanızı güvenli bir şekilde sıfırlayın.',
          resetPasswordLink: 'Parola sıfırlama bağlantısı',
          title: 'Parola sıfırlama',
        },
        resetPasswordSuccess: {
          message:
            'Nowadays hesabınızın parolasının başarıyla ayarlandığını size bildirmek için yazıyoruz.',
          preview:
            'Parolanız başarıyla ayarlandı. Şimdi güvenli bir şekilde giriş yapın.',
          title: 'Parolanız değişti',
        },
        signupLocalSuccess: {
          description:
            'Kayıt işlemini tamamlamak için e-posta adresinizi doğrulayın:',
          message:
            "Nowadays'e katıldığınız için teşekkür ederiz, sizin planlayıcınız, sizin kurallarınız.",
          preview: 'Hesabınız başarılı bir şekilde oluşturuldu.',
          title: "Nowadays'e hoşgeldiniz!",
          verificationLink: 'Doğrulama bağlantısı',
        },
        signupSocialSuccess: {
          message:
            "Nowadays'e katıldığınız için teşekkür ederiz, sizin planlayıcınız, sizin kurallarınız.",
          preview: 'Hesabınız başarılı bir şekilde oluşturuldu.',
          title: "Nowadays'e hoşgeldiniz!",
        },
        verifyEmailRequest: {
          description:
            'E-posta adresinizi doğrulamak için lütfen aşağıdaki bağlantıya tıklayın:',
          preview:
            'Kayıt işlemini tamamlamak için e-posta adresinizi doğrulayın.',
          title: 'E-posta doğrulama',
          verificationLink: 'Doğrulama bağlantısı',
        },
      },
      templates: {
        base: {
          closure: 'Mutlu planlamalar!',
          contact: 'Bizimle iletişime geçin',
          greating: 'Merhaba',
          ignore:
            'Bu isteği siz yapmadıysanız bu e-postayı yok sayabilirsiniz.',
          linkExpireInfo:
            'Doğrulama bağlantısı geçerlilik süresi {{hour}} saattir.',
          motto: 'Sizin Planlayıcınız, Sizin Kurallarınız',
        },
        changeEmailRequest: 'E-posta değiştirme isteği',
        changeEmailSuccess: 'E-posta değiştirme başarılı',
        createPasswordRequest: 'Parola oluşturma isteği',
        passwordResetRequest: 'Parola sıfırlama isteği',
        resetPasswordSuccess: 'Parola sıfırlama başarılı',
        signupLocalSuccess: 'E-posta ile kayıt başarılı',
        signupSocialSuccess: 'Sosyal medya ile kayıt başarılı',
        verifyEmailRequest: 'E-posta doğrulama isteği',
      },
      test: {
        form: { header: 'E-postayı test et', to: 'Kime' },
        title: 'E-postayı test et',
      },
      title: 'E-postalar',
      uodate: { title: 'E-postayı güncelle' },
      update: { title: 'E-postayı güncelle' },
    },
  },
  home: {
    user: {
      dau: 'Günlük aktif kullanıcı:',
      retention: 'Geri giriş:',
      total: 'Toplam kullanıcı:',
    },
  },
  language: {
    fontPacks: {
      add: {
        file: {
          form: {
            family: 'İsim',
            subsets: 'Karakterseti',
            title: 'Font paketini dosyadan yükle',
            variant: { style: 'Stil', weight: 'Kalınlık' },
            variants: 'Varyasyonlar',
          },
        },
        fromFile: { title: 'Dosyadan yükle' },
        fromWeb: { title: 'İnternetten yükle' },
        title: 'Font paketi ekle',
        web: {
          fontSize: 'Boyut',
          fontSubsets: 'Karakter setleri',
          header: {
            actions: 'İşlemler',
            family: 'İsim',
            lastModified: 'Son değiştirilme',
            popularity: 'Popülerlik',
            version: 'Versiyon',
          },
          title: 'Font paketleri',
          update: 'Güncelle',
          upload: 'Yükle',
          webFontsOffline: 'Font sunucusuna erişilemiyor',
        },
      },
      delete: {
        confirm: 'Font paketini kalıcı olarak silmek istediğinden emin misin?',
        title: 'Font paketini sil',
      },
      header: {
        actions: 'İşlemler',
        family: 'İsim',
        lastModified: 'Son değiştirilme',
        popularity: 'Popülerlik',
        version: 'Versiyon',
      },
      title: 'Font paketleri',
    },
    manageLocales: {
      languages: {
        add: 'Ekle',
        delete: {
          confirm: 'Dili silmek istediğinizden emin misiniz?',
          title: 'Sil',
        },
        notFound: 'Dil bulunamadı',
        title: 'Diller',
      },
      namespaces: {
        add: 'Ekle',
        delete: {
          confirm: 'Kapsamı silmek istediğinizden emin misiniz?',
          title: 'Sil',
        },
        form: {
          header: 'Uygulama kapsamı ekle',
          namespace: 'Uygulama kapsamı',
          regexError: '{{label}} sadece ayrıcı olarak (:) içerebilir',
        },
        notFound: 'Kapsam bulunamadı',
        title: 'Kapsam',
      },
      resources: {
        hotColHeader: { path: 'Yol (Klasör)' },
        hotMenu: {
          copy: 'Hücreyi kopyala',
          cut: 'Hücreyi kes',
          removeRow: 'Satırı kaldır',
          rowAbove: 'Satırı yukarı ekle',
          rowBelow: 'Satırı alta ekle',
        },
        notFound: 'Kaynak bulunamadı veya kapsam seçilmedi',
        prod: 'Diller yalnızca geliştirme modunda düzenlenebilir!',
        save: 'Değişiklikleri kaydet',
        search: 'Kaynak ara...',
        title: 'Kaynaklar',
      },
    },
  },
  login: {
    form: {
      email: 'E-posta',
      login: 'Oturum aç',
      password: 'Parola',
      rememberMe: 'Beni hatırla',
    },
    title: 'Oturum aç',
  },
  planner: {
    covers: {
      add: { title: 'Kapak ekle' },
      list: {
        delete: {
          confirm: 'Kapağı kalıcı olarak silmek istediğinden emin misin?',
          title: 'Kapağı sil',
        },
      },
      notFound: 'Kapak bulunamadı',
      title: 'Planlayıcı kapakları',
    },
  },
  reports: {
    close: {
      confirm: 'Raporu kapatmak istediğinden emin misin?',
      title: 'Raporu kapat',
    },
    delete: {
      confirm: 'Raporu silmek istediğinden emin misin?',
      title: 'Raporu sil',
    },
    detail: {
      form: {
        category: 'Kategory',
        closedAt: 'Kapatılma zamanı',
        closedBy: 'Kapatan',
        createdAt: 'Oluşturulma zamanı',
        description: 'Açıklama',
        header: 'Rapor detayları',
        reference: 'Referans',
        type: 'Tip',
        user: 'Oluşturan',
      },
      title: 'Rapor detayı',
    },
    filter: { active: 'Aktif', closed: 'Kapalı' },
    header: {
      actions: 'İşlemler',
      categories: {
        bug: 'Açık veya hata',
        feature: 'Özellik veya geliştirme',
        other: 'Diğer',
      },
      category: 'Category',
      createdAt: 'Oluşturulma zamanı',
      description: 'Açıklama',
      type: 'Tip',
      types: {
        application: 'Uygulama',
        feedback: 'Geri bildirim',
        sticker: 'Etiket',
        template: 'Şablon',
        widget: 'Araç',
      },
    },
    title: 'Raporlar',
  },
  sticker: {
    packs: {
      add: {
        form: { title: 'Etiket paketi ekle' },
        title: 'Etiket paketi ekle',
      },
      delete: {
        confirm:
          'Etiket paketini kalıcı olarak silmek istediğinden emin misin?',
        desc: 'Etiket paketi silindiğinde mevcut sayfalarda görüntülenemeyecektir, devredışı bırakılması önerilir.',
        title: 'Etiket paketini sil',
      },
      edit: { form: { title: 'Etiket paketi düzenle' } },
      form: { status: 'Durum' },
      header: {
        actions: 'İşlemler',
        createdAt: 'Oluşturulma zamanı',
        name: 'Paket ismi',
        status: 'Durum',
        total: 'Toplam etiket',
      },
      items: {
        add: {
          form: {
            allIsChangeable: 'Tümü değiştirilebilir renk',
            multiple: 'Çoklu ekle',
            single: 'Tek ekle',
            sticker: 'Etiket (svg)',
            title: 'Etiket ekle',
          },
          title: 'Etiket ekle',
        },
        edit: { form: { header: 'Etiketi düzenle' } },
        form: {
          common: {
            colors: 'Değiştirilebilir renkler',
            file: 'Çizim (svg)',
            keywords: 'Anahtar kelimeler',
            size: { height: 'Boy (px)', width: 'En (px)' },
            uploading: { file: 'Dosya', thumbnail: 'Küçük görsel' },
          },
        },
        menu: {
          delete: {
            dialog: {
              confirm: 'Etiketi kalıcı olarak silmek istediğinden emin misin?',
              desc: '*Bu işlem geri alınamaz',
            },
            title: 'Etiketi sil',
          },
          edit: { title: 'Etiketi düzenle' },
        },
        notFound: 'Etiket bulunamadı',
      },
      title: 'Etiket paketleri',
    },
  },
  user: {
    users: {
      delete: {
        confirm: 'Kullanıcıyı kalıcı olarak silmek istediğinden emin misin?',
      },
      deletePlanners: {
        confirm:
          'Kullanıcının planlayıcılarını kalıcı olarak silmek istediğinden emin misin?',
      },
      edit: {
        form: {
          email: 'E-posta',
          firstName: 'İsim',
          header: 'Kullanıcıyı düzenle',
          isActive: 'Durum',
          isVerified: 'Onaylanmış',
          lastName: 'Soyisim',
          role: 'Rol',
        },
      },
      header: {
        actions: 'İşlemler',
        createdAt: 'Oluşturulma zamanı',
        email: 'E-posta',
        fullName: 'İsim soyisim',
        isActive: 'Durum',
        lastLoginAt: 'Son oturum açma zamanı',
        role: 'Rol',
      },
      title: 'Kullanıcılar',
    },
  },
  widget: {
    sets: {
      add: { form: { title: 'Araç kümesi ekle' } },
      delete: {
        confirm: 'Araç kümesini kalıcı olarak silmek istediğinden emin misin?',
        desc: 'Araç kümesi silindiğinde mevcut sayfalarda görüntülenemeyecektir, devredışı bırakılması önerilir.',
        title: 'Araç kümesini sil',
      },
      edit: { form: { title: 'Araç kümesi düzenle' } },
      form: { status: 'Durum' },
      header: {
        actions: 'İşlemler',
        createdAt: 'Oluşturulma zamanı',
        name: 'Küme ismi',
        status: 'Durum',
        total: 'Toplam araç',
      },
      items: {
        add: { form: { title: 'Araç ekle' } },
        delete: {
          confirm: 'Aracı kalıcı olarak silmek istediğinden emin misin?',
          desc: 'Araç silindiğinde mevcut sayfalarda görüntülenemeyecektir, devredışı bırakılması önerilir.',
          title: 'Aracı sil',
        },
        edit: { form: { title: 'Araç düzenle' } },
        form: {
          code: 'Kod',
          keywords: 'Anahtar kelimeler',
          status: 'Durum',
          thumbnail: 'Küçük görsel (png)',
        },
        header: {
          actions: 'İşlemler',
          code: 'Kod',
          createdAt: 'Oluşturulma zamanı',
          name: 'İsim',
          status: 'Durum',
          thumbnail: 'Önizleme',
        },
      },
      title: 'Araç kümeleri',
    },
  },
};
