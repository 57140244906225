'use client';

import { classes, classNames } from '@nowadays/ui/utils';

import { LabelProps } from './Label.types';

const Label: React.FC<LabelProps> = ({
  label,
  htmlFor,
  className,
  children,
}) => {
  return (
    <label className={classNames(styles.label, className)} htmlFor={htmlFor}>
      {label}
      {children}
    </label>
  );
};

const styles = {
  label: classes(
    'py-1',
    'px-1',
    'text-2xs',
    'truncate',
    'block',
    'text-skin-muted',
  ),
};

export default Label;
