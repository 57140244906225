'use client';

import { useTranslate } from '@nowadays/ui/i18n/client';
import { classes } from '@nowadays/ui/utils';
import { useLocation } from 'react-router-dom';

import Disclosure from '../../../disclosure/disclosure/Disclosure';
import Icon from '../../../icon/Icon';
import RouterMenuButton from '../router-menu-button/RouterMenuButton';
import RouterMenuItem from '../router-menu-item/RouterMenuItem';
import { RouterChildMenuProps } from './RouterChildMenu.types';

const RouterChildMenu: React.FC<RouterChildMenuProps> = ({ item }) => {
  const translate = useTranslate();
  const { pathname } = useLocation();
  const defaultOpen = pathname.includes(item.path);

  const Button = ({ open }: { open: boolean }) => (
    <RouterMenuButton item={item}>
      <div className={styles.button}>
        {translate(item.title)}
        <Icon name={open ? 'Up' : 'Down'} className={styles.icon} />
      </div>
    </RouterMenuButton>
  );

  const Panel = item?.children?.map((child, key) => (
    <RouterMenuItem key={key} item={child} parentPath={item.path} />
  ));

  return (
    <Disclosure defaultOpen={defaultOpen}>
      <Disclosure.Button className={styles.disclosure} tabIndex={-1}>
        {Button}
      </Disclosure.Button>
      <Disclosure.Panel className={styles.panel}>{Panel}</Disclosure.Panel>
    </Disclosure>
  );
};

const styles = {
  disclosure: classes('w-full'),
  button: classes('flex', 'w-full', 'items-center', 'justify-between'),
  icon: classes('w-5', 'h-5'),
  panel: classes(
    'space-y-1',
    'ml-2',
    'pl-4',
    'border-l-2',
    'border-skin-silent',
  ),
};

export default RouterChildMenu;
