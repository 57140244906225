import { useTranslate } from '@nowadays/ui/i18n/client';
import { classes } from '@nowadays/ui/utils';
import React, { useState } from 'react';

import { Button } from '../../../../../button';
import { Label } from '../../../../label';
import { Text } from '../../../../text';
import { ImageNodeSizeProps } from './ImageNodeSize.types';

const ImageNodeSize: React.FC<ImageNodeSizeProps> = ({
  width: initialWidth,
  height: initialHeight,
  onSizeChange,
}) => {
  const translate = useTranslate();
  const [width, setWidth] = useState<string | number>(initialWidth);
  const [height, setHeight] = useState<string | number>(initialHeight);

  const handleWidthChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setWidth(e.target.value || '');

  const handleHeightChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setHeight(e.target.value || '');

  const handleConfirm = () => {
    const newWidth =
      width === 'auto' || width === '100%' ? width : `${width}px`;

    const newHeight =
      height === 'auto' || height === '100%' ? height : `${height}px`;

    onSizeChange({
      width: newWidth,
      height: newHeight,
    });
  };

  const parsePxToInt = (pxString: string) =>
    parseInt(pxString.replace(/\D/g, ''), 10) || '';

  const widthValue =
    width === 'auto' || width === '100%'
      ? width
      : parsePxToInt(width as string);

  const heightValue =
    height === 'auto' || height === '100%'
      ? height
      : parsePxToInt(height as string);

  return (
    <div className={styles.root}>
      <div>
        <Label
          label={translate('ui.components.inputs.richText.node.image.weight')}
        />
        <Text
          value={widthValue}
          onChange={handleWidthChange}
          input={{ className: styles.input }}
        />
      </div>

      <div>
        <Label
          label={translate('ui.components.inputs.richText.node.image.height')}
        />
        <Text
          value={heightValue}
          onChange={handleHeightChange}
          input={{ className: styles.input }}
        />
      </div>
      <Button
        color='accent'
        variant='contained'
        className='h-min'
        onClick={handleConfirm}
      >
        {translate('ui.components.inputs.richText.node.image.saveSize')}
      </Button>
    </div>
  );
};

const styles = {
  root: classes(
    'absolute',
    'bottom-1',
    'right-1',
    'p-1.5',
    'flex',
    'gap-1.5',
    'bg-skin-primary',
    'rounded-lg',
    'items-end',
    'border',
    'border-skin-silent',
  ),
  container: classes('flex', 'gap-2'),
  input: classes('w-24'),
};

export default ImageNodeSize;
