'use client';

import { useTranslate } from '@nowadays/ui/i18n/client';
import { classes, classNames } from '@nowadays/ui/utils';
import { useLocation, useNavigate } from 'react-router-dom';

import AnchorButton from '../../../button/anchor-button/AnchorButton';
import { RouterMenuButtonProps } from './RouterMenuButton.types';

const RouterMenuButton: React.FC<RouterMenuButtonProps> = ({
  item,
  parentPath,
  className,
  ...props
}) => {
  const navigate = useNavigate();
  const translate = useTranslate();
  const { pathname } = useLocation();
  const { title, icon, path, children } = item;

  const activePath = (parentPath && parentPath + '/' + path) || path;

  const isActive = activePath === pathname;

  const handleClick = () =>
    !children && (parentPath ? navigate(activePath) : navigate(path));

  const handleKeydown = (e: React.KeyboardEvent<HTMLAnchorElement>) =>
    e.code === 'Enter' && handleClick();

  return (
    <AnchorButton
      variant='flat'
      onClick={handleClick}
      onKeyDown={handleKeydown}
      color={isActive ? 'accent' : 'primary'}
      startIcon={icon && { name: icon, className: styles.icon }}
      tabIndex={0}
      className={classNames(
        styles.root,
        isActive && styles.active,
        parentPath && styles.child,
        className,
      )}
      text={translate(title)}
      {...props}
    />
  );
};

const styles = {
  root: classes('p-1.5', 'w-full', 'justify-start'),
  active: classes(
    'bg-skin-silent',
    'hover:bg-skin-inverted',
    'hover:active:bg-skin-inverted',
  ),
  icon: classes('w-4', 'h-4'),
  child: classes('text-xs'),
};

export default RouterMenuButton;
