'use client';

import { useTranslate } from '@nowadays/ui/i18n/client';
import { Fragment, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation, useRoutes } from 'react-router-dom';

import { RouterMenuItem } from './RouterMenu.types';

interface RouterMapperProps {
  routes: RouterMenuItem[];
}

const flatten = (routeItems: RouterMenuItem[]): RouterMenuItem[] => {
  const recursive = (item: RouterMenuItem, parentPath?: string) => {
    const items: RouterMenuItem[] = [];

    if (item?.children) {
      const subItems = item?.children?.map((child) =>
        recursive(child, item.path),
      );

      return items.concat(...subItems);
    } else {
      items.push({
        ...item,
        path: parentPath ? parentPath + '/' + item.path : item.path,
      });
    }

    return items;
  };

  return routeItems
    .map((route) => recursive(route))
    .reduce((c, p) => [...c, ...p], []);
};

const RouterMapper: React.FC<RouterMapperProps> = ({ routes }) => {
  const translate = useTranslate();
  const { pathname } = useLocation();

  const flattened = flatten(routes);
  const element = useRoutes(routes);
  const activePath = flattened.find((item) => item.path === pathname);

  const title = activePath
    ? `${translate(activePath.title)} | Nowadays`
    : 'Nowadays';

  useEffect(() => {
    if (activePath) {
      document.title = title;
    }
  }, [activePath]);

  return (
    <Fragment>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      {element}
    </Fragment>
  );
};

export default RouterMapper;
